
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Card from "primevue/card";
import TextArea from "primevue/textarea";

import { OperItem } from "@/types/state/production";

export default defineComponent({
  components: {
    InputText,
    InputNumber,
    Calendar,
    Dropdown,
    Card,
    TextArea,
  },
  props: {
    workCenterList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      orderToEdit: {} as any,
      workCenter: "",
      operationDescription: "",
    };
  },
  computed: {
    ...mapGetters({
      getActiveWorkOrder: "workorder/getActiveWorkOrder",
      correlativeExists: "fdict/correlativeExists",
      getWOSubStatusItems: "woControl/getSubStatusItems",
      getFormatWorkOrderStatusList: "woControl/getFormatWorkOrderStatusList",
      getFormatWorkOrderDisplayStatusList:
        "woControl/getFormatWorkOrderDisplayStatusList",
    }),

    showDisplayStatus(): boolean {
      return this.correlativeExists("WO", "DISPLAY.STATUS");
    },
  },
  watch: {
    getActiveWorkOrder(newOrder) {
      if (this.getActiveWorkOrder) {
        this.orderToEdit = this.getActiveWorkOrder.record;
        this.getWorkCenter();
      }
    },
  },
  mounted() {
    this.orderToEdit = this.getActiveWorkOrder.record;
    this.getWorkCenter();
  },
  methods: {
    getWorkCenter() {
      if (this.orderToEdit.oper_items && this.orderToEdit.oper_items.length) {
        const orderWorkCenter = this.orderToEdit.oper_items.find(
          (item: OperItem) => !item.oper.includes("-"),
        );
        this.workCenter = orderWorkCenter ? orderWorkCenter.work_center : "";
      }
    },
    setWorkCenter() {
      if (this.orderToEdit.oper_items && this.orderToEdit.oper_items.length) {
        const selectedWorkCenter: any = this.workCenterList.find(
          (item: any) => item.work_center === this.workCenter,
        );
        this.orderToEdit.oper_items.forEach((item: any) => {
          item.work_center = this.workCenter;
          item.operation_description = selectedWorkCenter.description || "";
        });
      }
    },
  },
});
